import request from '@/api/index'
import axios from 'axios'
import {getUrl} from '../case'
export const fromsubmit = (type,data)=>{
    let url = getUrl(type)
    return request({
        url:url+"/api/yd/from/submit",
        method:"POST",
        data,
    })
}

