import axios from 'axios'
const requestInstance = axios.create({
  timeout: 1000 * 60,
  withCredentials: true, 
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json; charset=utf-8',
  },
})

requestInstance.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

// 添加响应拦截器
requestInstance.interceptors.response.use(
  function (response) {
    const data = response.data
    return data
  },
  function (error) {
    return Promise.reject(error)
  },
)

export default requestInstance
