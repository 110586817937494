<template>
    <div class="header">
      <div class="headerBox1box">
        <div class="headerBox1">
          <div class="left btn">
            <img src="@/assets/icon/iconH.png" alt=""  @click="jumpRouterchild('home','home')">
          </div>
          <div class="right">
            <div class="png">
              <img src="@/assets/icon/facebookheader.png" alt="" @click.stop="jumpUrl('https://www.facebook.com/eiceducationvn')">
              <img src="@/assets/icon/emil.png" alt="" @click.stop="jumpRouterchild('contact','user')">
              <img src="@/assets/icon/phone.png" alt="" @click.stop="jumpRouterchild('contact','user',{idName:'tabBox'})">
            </div>
            <div>
              <el-button class="click" size="small" @click.stop="jumpRouterchild('contact','user',{idName:'box1_2'})">{{ jsonObj.header.btn }}</el-button>
            </div>
          </div>
        </div>
      </div>
      <div  class="headerBox">
        <div class="nav">
          <div class="btn navitem" :class="state.windowUrl == '/home' ? 'yellowNav':''" @click="jumpRouterchild('home','home')">
            {{ jsonObj.header.home }}
          </div>
          <div class="btn navitem" :class="state.windowUrl == '/school' ? 'yellowNav':''" @click="jumpRouterchild('specialty','school')" @mouseenter="mouseenter('school')" @mouseleave="mouseleave">
            {{ jsonObj.header.school }}
            <div class="navitemChil" v-show="state.header.mouseenterName == 'school'">
              <div @click.stop="jumpRouterchild('specialty','school')">
                  {{ jsonObj.header.specialty }}
              </div>
              <div @click.stop="jumpRouterchild('schoolmajor','school')">
                {{ jsonObj.header.schoolmajor }}
              </div>
              
            </div>
          </div>
          <div class="btn navitem" :class="state.windowUrl == '/serve' ? 'yellowNav':''" @click="jumpRouterchild('cultivate','serve')" @mouseenter="mouseenter('serve')" @mouseleave="mouseleave">
            {{ jsonObj.header.serve }}
            <div class="navitemChil" v-show="state.header.mouseenterName == 'serve'">
              <div @click.stop="jumpRouterchild('cultivate','serve')">
                  {{ jsonObj.header.cultivate }}
              </div>
              <div @click.stop="jumpRouterchild('studyAbroadLong','serve')">
                  {{ jsonObj.header.studyAbroadLong }}
              </div>
              <div @click.stop="jumpRouterchild('studyAbroadShort','serve')">
                  {{ jsonObj.header.studyAbroadShort }}
              </div>
            </div>
          </div>
          <div class="btn navitem navitem4" :class="state.windowUrl == '/scholarship' ? 'yellowNav':''" @click="jumpRouterchild('scholarship','scholarship')">
            {{ jsonObj.header.jiangjin }}
          </div>
          <div class="btn navitem" :class="state.windowUrl == '/user' ? 'yellowNav':''" @click="jumpRouterchild('story','user')" @mouseenter="mouseenter('user')" @mouseleave="mouseleave">
            {{ jsonObj.header.user }}
            <div class="navitemChil" v-show="state.header.mouseenterName == 'user'">
              <div @click.stop="jumpRouterchild('story','user')" >
                  {{ jsonObj.header.story }}
              </div>
              <div @click.stop="jumpRouterchild('partner','user')">
                  {{ jsonObj.header.partner }}
              </div>
              <div @click.stop="jumpRouterchild('contact','user')">
                  {{ jsonObj.header.contact }}
              </div>
            </div>
          </div>
          
        </div>
        <div class="input">
            <img class="btn" src="@/assets/icon/inputIcom.png" alt="" srcset="">
            <input class="inputv" v-model="jsonObj.headerinput" placeholder=""/>
          </div>
      </div>
    
  </div>
</template>

<script setup name="header">
import { nextTick, reactive,onMounted, watch } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import { jsonObj,imgUrl,country,navChilder} from '@/utlis/constJSON.js'
import { getUrl} from '@/utlis/util.js'
import {goRouter} from '@/router/index.js'
import {path} from '@/router/path.js'
const route = useRoute()
const router = useRouter()
const state = reactive({
  windowUrl:"",
  header:{
    isShow:false,
    mouseenterName:"",
    countryShow:false,
  }
})
const changeCountry = (item)=>{
  country.value = item.value
  state.countryShow = !state.countryShow
} 
const mouseenter = (str)=>{
  state.header.mouseenterName = str
}
const mouseleave=()=>{
  if(state.header.mouseenterName){
    state.header.mouseenterName = ''
  }
}
const jumpRouterchild = (str,fatStr,query={})=>{
  goRouter(str,query)
  state.windowUrl=`/${fatStr}`
}
const jumpUrl = (url)=>{
  window.open(url, '_blank');
}

onMounted(()=>{
  state.windowUrl = getUrl(path,'path')
})

</script>
<style lang="scss" scoped>
.header{
  width: 100vw;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
  background-color: #004163;
  padding: 0;
  .headerBox1box{
    background-color: #fff;
  }
  .headerBox1{
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    text-align: center;
    margin: 0 auto;
    width: 60rem;
    .left{
      width: 12rem;
      img{
        width: 100%;
      }
    }
    .right{
      display: flex;
      justify-content: right;
        .png{
          display: flex;
          justify-content: center;
          margin-right: 1.8rem;
          img{
            width: 1.07rem;
            height: 1.07rem;
            margin-top: 0.5rem;
            margin-left: 1.05rem;
            cursor: pointer;
          }
        }
        .click{
          width: 6rem;
          height: 1.6rem;
          line-height: 1.6rem;
          background-color: #F5AA3B;
          color: #004163;
          font-size: 0.7rem;
          margin-top: 0.3rem;
        }
      }
  }
  .headerBox{
    display: flex;
    justify-content: space-between;
    background-color: #004163;
    color: #fff;
    text-align: center;
    margin: 0 auto;
    width: 60rem;
    .nav{
    display: flex;
    justify-content: left;
    font-weight: 400;
    font-size: 0.9rem;
    .btn{
      padding:1.55rem 1.5rem;
      white-space:nowrap;
    }
    .yellowNav{
        background: #F5AA3B;
        color: #004163;
    }
    
    .navitem{
      position: relative;
      .navitemChil{
        min-width: 100%;
        width: auto;
        text-align: center;
        position: absolute;
        top: 4.5rem;
        left: 0;
        z-index: 1;
        color: #000;
        font-size: 0.9rem;
        div{
          padding: 1.5rem 1.65rem;
          border: 0.05rem solid #D8D8D8;
          background: #FFFFFF;
        }
      }
      .navitemChil div:hover{
        font-weight: 600;
        color: #004163;
      }
    }
    .navitem4{
    }
  }
    .input{
      padding:  0.95rem 0 0;
      position: relative;
      img{
        width: 1.2rem;
        height: 1.2rem;
        position: absolute;
        top: 1.5rem;
        left: 0.8rem;
      }
      .inputv{
        width: 10rem;
        height:2.1rem;
        border: 0.05rem solid #F5AA3B;
        border-radius: 1.05rem 1.05rem 1.05rem 1.05rem; 
        padding-left: 2rem;
        font-size: 0.8rem;
      }
      .inputv:focus {
        /* 去除边框 */
        outline: none;
      }
    }
  }
}
</style>