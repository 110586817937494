import { path } from '@/router/path'
import { createStore } from "vuex";

export default createStore({
  state: {
    path:path||[],
    mobileIsHeaderOpenNav:false
  },
  getters: {
    getterPath(state){
      return state.path
    },
    gettermobileIsHeaderOpenNav(state){
      return state.mobileIsHeaderOpenNav
    },
  },
  mutations: {
    changePath(state,path){
      state.path = path
    },
    changemobileIsHeaderOpenNav(state,mobileIsHeaderOpenNav){
      state.mobileIsHeaderOpenNav = mobileIsHeaderOpenNav
    }
  },
  actions: {
    updatePath(context,path){
      context.commit('changePath',path)
    },
    updatemobileIsHeaderOpenNav(context,mobileIsHeaderOpenNav){
      context.commit('changemobileIsHeaderOpenNav',mobileIsHeaderOpenNav)
    }
  },
  modules: {},
});
