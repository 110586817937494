<template>
    <div class="mobileHeader" id="mobileHeader">
      <div class="herder">
        <img src="@/assets/icon/navIcon.png" alt="" class="herder1" @click.stop="isOpenNav">
        <img src="@/assets/icon/icon.png" alt="" class="herderc">
        <img src="@/assets/icon/searchYellow.png" alt="" class="herder1">
      </div>
        <div class="navBoxmeng" v-show="mobileIsHeaderOpenNav" @click="isOpenNav">
          <div class="navBox">
            <el-tree
              :data="path"
              :props="defaultProps"
              accordion
              @node-click="handleNodeClick"
            >
            <template #default="{ data }">
              <div :class="data.isclick ? 'origin':''">
                {{data.label }}
              </div>
            </template>
            </el-tree>
          </div>
        </div>
        
    </div>
  </template>
  <script setup name="mobileHeader">
  import { reactive ,computed, nextTick} from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import {jsonObj,imgUrl} from '@/utlis/constJSON.js'
  import { useStore } from "vuex";
  const store = useStore()
  const path = computed(() => store.state.path);
  const mobileIsHeaderOpenNav = computed(() => store.state.mobileIsHeaderOpenNav);
  const router = useRouter()
  const state = reactive({
  })
  const defaultProps = {
    children: 'children',
    label: 'label',
  }
  const isOpenNav = ()=>{
    store.dispatch('updatemobileIsHeaderOpenNav',!mobileIsHeaderOpenNav.value)
  }
  const handleNodeClick = (e) => {
    if(e.children){
      return
    }
    router.push({
      path: e.path,
      query:{},
    })
    
    path.value.forEach(v=>{
      v.isclick = false
      if(v?.children){
        v.children.forEach(m=>{
          m.isclick = false
          if(m.path == e.path){
            m.isclick = !m.isclick
            v.isclick = true
          }
        })
      }else{
        if(v.path == e.path){
          v.isclick = true
        }
      }
    })
    store.dispatch('updatePath',path.value)
    store.dispatch('updatemobileIsHeaderOpenNav',false)
  }
  </script>
  <style lang="scss" scoped>
    .mobileHeader{
      width: 100%;
      background: #004163;
      color: #fff;
      position: fixed;
      top: 0;
      z-index: 10000;
      .herder{
        padding: 15px 30px;
        display: flex;
        justify-content: space-between;
        .herder1{
          width: 7.35rem;
        }
        .herderc{
          width: 42rem;
        }
      }
      .navBoxmeng{
        width: 100%;
        position: absolute;
        left:0;
        top: 15.75rem;
        z-index: 1;
        background-color: rgba(127, 127, 127,0.3);
      }
      .navBox{
        position: absolute;
        top:0;
        left: 0;
        z-index: 1;
        width: 60%;
        border: 1px solid #f7f3f3;
      }
        :deep() .el-tree-node__content {
          padding: 5.25rem 0 5.25rem 5.25rem;
          font-weight: 700;
          color: #004163;
          font-size: 3.15rem;
        }
        :deep() .el-tree-node__content{
          border-bottom: 1px solid #f7f3f3;
        }
        .origin{
          color: #F5AA3B;
        }
    }
    .fade-left-enter-from {
      opacity: 0;
      transform: translate(-100%, 0);
    }

    .fade-left-enter-to {
      opacity: 1;
      transform: translate(0, 0);
    }

    .fade-left-leave-from {
      opacity: 1;
      transform: translate(0, 0);
    }

    .fade-left-leave-to {
      opacity: 0;
      transform: translate(-100%, 0);
    }
  </style>
  